import React from "react";
import { Redirect, useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import postlist from "../../posts.json";
import Markdown from "react-markdown";
import { PostAuthor, PostCard, PostDate } from "./PostElements";
import { BlogPostContainer } from "../blog/BlogElements";
import FullbarSolid from "../../components/Navbar/NavbarSolid";
import AuthorCard from "../../components/AuthorCard";
import mundi from "../Main/mainImage/mundi.jpg";
import choolhun from "../Main/mainImage/choolhun.jpg";
import Footer from "../../components/Footer";
import { ImageContainer, ImageHolder } from "./PostElements";

function Author(props) {
  const author = props.author;
  console.log(props.author);
  if (props.author === "Ajay Mundi") {
    <AuthorCard
      name={props.author}
      date={props.date}
      image={mundi}
    ></AuthorCard>;
  }

  return (
    <AuthorCard
      name={props.author}
      date={props.date}
      image={choolhun}
    ></AuthorCard>
  );
}

const Post = (props) => {
  const { id } = useParams();
  const fetchedPost = {};
  let postExists = false;
  Author(props);
  postlist.forEach((post, i) => {
    if (id === post.id) {
      fetchedPost.id = post.id;
      fetchedPost.thumbnail = post.thumbnail;
      fetchedPost.title = post.title ? post.title : "No title given";
      fetchedPost.author = post.author ? post.author : "No author given";
      fetchedPost.date = post.date ? post.date : "No date given";
      fetchedPost.content = post.content ? post.content : "No content given";
      postExists = true;
    }
  });

  if (postExists === false) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <FullbarSolid></FullbarSolid>
      <BlogPostContainer>
        <PostCard>
          <h1> {fetchedPost.title}</h1>
          <Author
            date={fetchedPost.date}
            author={fetchedPost.author}
            image={mundi}
          ></Author>
          <ImageHolder>
            <ImageContainer src={fetchedPost.thumbnail}></ImageContainer>
          </ImageHolder>

          <Markdown
            rehypePlugins={[rehypeRaw]}
            children={fetchedPost.content}
          />
        </PostCard>
      </BlogPostContainer>
      <Footer></Footer>
    </>
  );
};

export default Post;
