import React, { useState } from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
};

export default function MathRender(props) {
  return (
    <MathJaxContext version={3} config={config}>
      <div>
        <MathJax inline dynamic>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={props.content}
          ></ReactMarkdown>
        </MathJax>
      </div>
    </MathJaxContext>
  );
}
