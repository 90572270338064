import React from "react";
import {
  MainContainer,
  TitleContainer,
  Heading,
  NavBtn,
  ImageHolder,
  ViewSplit,
  ViewSplitTwo,
  Sub,
  GoBtn,
} from "./MainElements";

import styled from "styled-components";

export const TitleContainerAlt = styled(TitleContainer)`
  height: 60vh;
  width: 40vw;
  margin-left: 2vw;
  text-alignment: center;
  text-align: center;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  float: left;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SubAlt = styled(Sub)`
  text-size: 2rem;
  margin-right: 0vw;
  line-height: 1.6;
  clear: right;
  text-align: right;
  color: ${(props) => props.theme.secondary};
  font-size: 1.75rem;
  font-family: "Lucida Console", "Courier New", monospace;
  float: right;

  @media (max-width: 1024px) {
    font-size: 1.75rem;
    text-align: center;
  }
  @media (max-width: 812px) {
    font-size: 1rem;
  }
`;

const IntroPageAlt = (props) => {
  return (
    <MainContainer>
      <ViewSplitTwo>
        <Heading>{props.name}</Heading>
        <hr></hr>
        <SubAlt>{props.description}</SubAlt>
        <GoBtn to={props.go}>Learn More</GoBtn>
      </ViewSplitTwo>
      <TitleContainerAlt image={props.image}></TitleContainerAlt>
    </MainContainer>
  );
};

export default IntroPageAlt;
