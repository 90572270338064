import styled from "styled-components";

export const UtfContainer = styled.div`
  font-family: "Lucida Console", "Courier New", monospace;
  line-height: 1.5rem;
  display: block;
  width: 100%;
  height: 85vh;
  justify-content: center;
  background-color: #cbc3e3;
  color: grey;
  padding-top: 15px;
`;

export const UtfInButton = styled.button`
  background-color: white;
  border: 1px solid black;
  color: #494464;
  font-size: 3rem;
  width: 5vw;
  height: 10.3vh;

  &:hover {
    border: 2px solid black;
  }
`;
