import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Subheader } from "./listTreeElements";
import { useHistory } from "react-router-dom";
import { StyledComponent } from "@emotion/styled";
import { ClassNames } from "@emotion/react";
import { alpha, styled } from "@mui/material/styles";

const NestedList = (props) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const openNew = () => {};
  const history = useHistory();
  return (
    <List
      sx={{
        width: "80%",
        maxWidth: 200,
        bgcolor: "background.paper",
        borderRight: 1,
        borderTop: 1,
        borderColor: "black",
        marginLeft: 4,
        marginRight: 1,
        marginTop: -2.5,
        padding: 0,
        fontfamily: "Courier New",
        color: "black",
        textAlign: "Center",
        fontSize: 2,
      }}
    >
      {props.listIn.map((entry) => (
        <ListItemButton
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            borderBottom: 1,
            borderLeft: 1,
            borderColor: "black",
          }}
          onClick={() => history.push(`/Learn/Structural/${entry.id}`)}
        >
          <ListItemText primary={entry.title} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default NestedList;
