import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import SusBridge from "./mainImage/susBridge4.jpg";
import Back from "./mainImage/back.jpg";
import Brick from "./mainImage/brick.jpg";
import Arch from "./mainImage/arch.jpg";
import { fabars } from "react-icons";

export const MainContainer = styled.div`
  background-image: url(${SusBridge});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin-bottom: 10vh;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const TitleContainer = styled.div`
  height: 85vh;
  width: 96.5vw;
  text-alignment: center;
  text-align: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const TotalContainer = styled.div`
  padding-top: 10vh;
  margin-bottom: 10vh;
  background-color: ${(props) => props.colour};
  @media screen and (max-width: 1024px) {
    padding-top: 10vh;
    margin-bottom: 10vh;
  }
`;
export const Welcome = styled.div`
  text-alignment: center;
  text-align: center;
  margin-top: 20vh;
`;

export const Heading = styled.h1`
  text-align: center;
  color: white;
  font-size: 4rem;
  margin-top: 20vh;
  font-family: "Lucida Console", "Courier New", monospace;

  @media (max-width: 1024px) {
    font-size: 2rem;
  }
`;

export const AboutBtn = styled(LinkS)`
  text-align: center;
  background-color: Transparent;
  opacity: 100;
  font-size: 1rem;
  color: white;
  height: 100px;
  border: 2px solid white;
  margin-top: 2vh;
  font-family: "Lucida Console", "Courier New", monospace;
  height: 30px;
  padding: 10px;
  width: auto;
  :hover {
    border: 2px solid grey;
    color: grey;
  }
`;

export const NavBtn = styled.button`
  text-align: center;
  background-color: Transparent;
  font-size: 1rem;
  color: black;
  border: 2px solid black;
  font-family: "Lucida Console", "Courier New", monospace;
  height: 20px;
  position: sticky;
  margin-top: 60vh;
  margin-right: 5px;
  top: 0;
  z-index: 10;
  float: right;
  :hover {
    border: 2px solid gray;
    color: gray;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const TestBed = styled.nav`

justify-content: right;
align-items:right;
font-size: 1rem;
position: sticky
top: 0;
z-index: 10;
opacitty:0;
height: 10vh;
margin:10px;
width:100vw;
float:right;
@media screen and (max-width:1024px){
    transition: 0.8s all ease;
}
`;
