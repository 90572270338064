import React from "react";
import postlist from "../../posts.json";
import ReactMarkdown, { propTypes } from "react-markdown";
import RecipeReviewCard from ".";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BlogListContainer } from "./ListStyle";
import Footer from "../../components/Footer";
import { AboutContainer } from "../About/AboutElements";
import { Link } from "react-router-dom";
import Featured from "./featured";
import { BlogListBanner } from "./ListStyle";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
});

/*let featured = postlist[2];
postlist.splice(2, 1);*/

const BlogList = () => {
  const classes = useStyles();
  const excerptList = postlist.map((post) => {
    return post.content.split(" ").slice(0, 20).join(" ");
  });
  return (
    <>
      <BlogListBanner></BlogListBanner>
      <BlogListContainer>
        <Grid
          container
          spacing={5}
          className={classes.gridContainer}
          justifyContent="center"
        >
          {postlist.length &&
            postlist.map((post, i) => {
              return (
                <Grid item xs={12} sm={6} md={6}>
                  <RecipeReviewCard
                    key={i}
                    id={post.id}
                    image={post.thumbnail}
                    title={post.title}
                    date={post.date}
                    author={post.author}
                    content={post.summary}
                  ></RecipeReviewCard>
                </Grid>
              );
            })}
        </Grid>
      </BlogListContainer>
    </>
  );
};

export default BlogList;
