import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../pages/Main/mainImage/logo.jpg";

export const FooterContainer = styled.div`
  height: 20vh;
  background-color: #494464;
  bottom: 0;
  padding: 10px;
  padding-top: 20px;
  padding: 5vh;

  @media screen and (max-height: 1367px) {
    height: 25vh;
  }

  @media screen and (max-height: 825px) {
    height: 50vh;
  }
`;

export const Logo = styled.div`
  position: sticky;
  height: 15vh;
  width: 15vh;
  float: left;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ImageLogo = styled.img`
  height: 15vh;
  width: 15vh;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const LinkR = styled(Link)`
  color: white;
  font-size: 1.5rem;
  line-height: 1.5;
  padding-left: 2vw;
  text-decoration: none;
`;

export const LilContainer = styled.div`
  height: 2vw;
  margin-top: 2vh;
  padding-bottom: 2vh;
  margin-right: 8vw;
  background-color: #494464;
  text-align: center;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
