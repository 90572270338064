import React from "react";
import {
  TopbarContainer,
  Icon,
  CloseIcon,
  TopbarLink,
  TopbarWrapper,
  TopbarMenu,
  ListItem,
} from "./TopbarElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBook,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

const TopBar = ({ isOpen, toggle }) => {
  return (
    <TopbarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <TopbarWrapper>
        <TopbarMenu>
          <TopbarLink to="/">
            <ListItem>
              Home <FontAwesomeIcon icon={faHome} />
            </ListItem>
          </TopbarLink>
          <TopbarLink to="/articles">
            <ListItem>
              Articles <FontAwesomeIcon icon={faBook} />
            </ListItem>
          </TopbarLink>
          <TopbarLink to="/learn">
            <ListItem>
              Learn <FontAwesomeIcon icon={faGraduationCap} />
            </ListItem>
          </TopbarLink>
        </TopbarMenu>
      </TopbarWrapper>
    </TopbarContainer>
  );
};

export default TopBar;
