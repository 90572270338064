import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CardActionArea } from "@material-ui/core";
import SusBridge from "../Main/mainImage/struct.png";
import { Link } from "react-router-dom";
import styled from "styled-components";

const useStyles = makeStyles({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
    textdecoration: "none",
  },

  card: {
    position: "relative",
    textdecoration: "none",
  },

  overlay: {
    position: "absolute",
    top: "20px",
    left: "20px",
    color: "black",
    backgroundColor: "white",
    textdecoration: "none",
  },

  container: {
    width: "80%",
    height: "80%",
    textdecoration: "none",
  },
});

export const LinkClean = styled(Link)`
  text-decoration: none;
`;

export default function SimpleCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <LinkClean to={props.Go}>
      <Card>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={props.image}
            title={props.name}
            style={useStyles.media}
          />
          <CardContent style={useStyles.overlay}>
            <Typography variant="h5" component="h2">
              {props.name}
            </Typography>

            <Typography variant="body2" component="p">
              {props.description}
              <br />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </LinkClean>
  );
}
