import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const postList = styled.p`
   {
    text-align: center;
    align-items: center;
  }
`;

export const PostCard = styled.div`
  font-family: "Lucida Console", "Courier New", monospace;
  color: #666666;
  margin: auto;
  margin-top: 40px;
  line-height: 1.5rem;
`;

export const PostAuthor = styled.h3`
  font-family: "Lucida Console", "Courier New", monospace;
  color: #666666;
  font-size: 1rem;
  text-align: left;
  margin-left: 10px;
`;

export const PostDate = styled.h3`
  font-family: "Lucida Console", "Courier New", monospace;
  color: #666666;
  font-size: 1rem;
  text-align: left;
  margin-left: 10px;
`;

export const ImageContainer = styled.img`
  width: 44vw;
  padding-top: 2vh;
  padding-bottom: 4vh;
`;
export const ImageHolder = styled.div`
  text-align: center;
`;
