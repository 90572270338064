import React from "react";
import TitlePage from "./Main";

const Home = () => {
  return (
    <>
      <TitlePage></TitlePage>
    </>
  );
};

export default Home;
