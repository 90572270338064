import { getThemeProps } from "@mui/system";
import React from "react";
import { AuthorContainer, AuthorText, Image } from "./AuthorElement";
import { AuthorLink } from "./AuthorElement";

const AuthorCard = (props) => {
  return (
    <AuthorContainer>
      <Image src={props.image}></Image>
      <AuthorText>
        By:<AuthorLink to={props.name}>{props.name}</AuthorLink>
      </AuthorText>
      <AuthorText>{props.date}</AuthorText>
    </AuthorContainer>
  );
};

export default AuthorCard;
