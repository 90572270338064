import React from "react";
import styled from "styled-components";

export const Subheader = styled.div`
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  color: "black";
  font-family: "Lucida Console", "Courier New", monospace;
  padding-left: 25px;
  padding-top: 25px;
  font-weight: bold;
  margin: auto;
  text-decoration: none;
  text-align: left;

  @media screen and (max-width: 1024px) {
    margin-left: 5px;
  }
`;
