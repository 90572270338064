import styled from "styled-components";
import { Link } from "react-router-dom";

export const AuthorContainer = styled.div`
  line-height: 0.1;
  padding-bottom: 30px;
  padding-left: 30px;
`;

export const AuthorText = styled.p`
  padding-top: 17px;
  padding-left: 90px;
  font-size: 14px;
  display: block;
  text-align: left;
`;

export const Image = styled.img`
  width: 70px;
  height: 70px;
  float: left;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const AuthorLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: #494464;
`;
