import React, { useState } from "react";
import NavbarSolid from "../Navbar/NavbarSolid";
import TopBar from "../TopBar";

const FullbarSolid = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <TopBar isOpen={isOpen} toggle={toggle} />
      <NavbarSolid Location={props.Location} toggle={toggle} />
    </>
  );
};

export default FullbarSolid;
