import styled from "styled-components";
import { Link } from "react-router-dom";

export const EduContainer = styled.div`
  font-family: "Lucida Console", "Courier New", monospace;
  margin-top: 40px;
  display: flex;
  margin-left: 0;
  margin-right: auto;
  width: 80%;
  font-size: 1rem;
`;

export const NextPrev = styled.button`
  text-align: center;
  background-color: #494464;
  opacity: 100;
  font-size: 1rem;
  color: white;
  border: 2px solid white;
  font-family: "Lucida Console", "Courier New", monospace;
  padding: 8px;
  margin: 5px;
  :hover {
    border: 2px solid grey;
    color: grey;
  }
`;
export const EduCard = styled.div`
  font-family: "Lucida Console", "Courier New", monospace;
  color: "black";
  margin: auto;
  margin-top: 40px;
  font-size: 1.5rem;
  margin-bottom: 40px;
  margin-left: 5%;
  line-height: 2;
  width: 80%;
  text-decoration: none;
`;
