import styled from "styled-components";
import { Link } from "react-router-dom";

export const postList = styled.p`
   {
    text-align: center;
    align-items: center;
  }
`;

export const PageHead = styled.h1`
   {
    clear: left;
    text-align: center;
    color: #666666;
    font-size: 30px;
    font-family: "Lucida Console", "Courier New", monospace;
  }
`;

export const PostCard = styled.div`
  font-family: "Lucida Console", "Courier New", monospace;
  color: #666666;
  border: 1px solid #666666;
  margin-top: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const PostAuthor = styled.h3`
  font-family: "Lucida Console", "Courier New", monospace;
  color: #666666;
  font-size: 0.75rem;
  text-align: left;
  margin-left: 10px;
`;

export const PostDate = styled.h3`
  font-family: "Lucida Console", "Courier New", monospace;
  color: #666666;
  font-size: 0.75rem;
  text-align: left;
  margin-left: 10px;
`;

export const PostParagraph = styled.p`
  font-family: "Lucida Console", "Courier New", monospace;
  color: #666666;
  font-size: 1rem;
  text-align: left;
  margin-left: 10px;
`;

export const PostHeader = styled.h1`
  font-family: "Lucida Console", "Courier New", monospace;
  color: #666666;
  font-size: 2rem;
  text-align: left;
  margin-left: 10px;
`;

export const TitleLink = styled(Link)`
  cursor: pointer;
  color: #666666;
  font-family: "Lucinda Console";
  text-decoration: none;
`;

export const Image = styled.img`
  width: 100%;
`;
