import styled from "styled-components";

export const BlogContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-top: -10vh;

  @media (max-width: 1024px) {
    width: 90%;
    align-items: center;
  } ;
`;

export const BlogPostContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 50%;

  @media (max-width: 1024px) {
    width: 90%;
    align-items: center;
  } ;
`;
