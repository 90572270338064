import React from "react";
import Navbar from "../../components/Navbar";
import PostList from "../Postlist";
import { BlogContainer } from "./BlogElements";
import NavbarSolid from "../../components/Navbar/NavbarSolid";
import FullbarSolid from "../../components/FullbarSolid";
import Footer from "../../components/Footer";
import RecipeReviewCard from "../BlogCard";
import BlogList from "../BlogCard/blogList";
import About from "../About";
const Blog = () => {
  return (
    <>
      <FullbarSolid Location={"ARTICLES"}></FullbarSolid>
      <BlogList></BlogList>
      <Footer></Footer>
    </>
  );
};

export default Blog;
