import React from "react";
import Navbar from "../../components/Navbar";

const NoPage = () => {
  return (
    <>
      <Navbar></Navbar>
      <h1>Page Not Found</h1>
    </>
  );
};

export default NoPage;
