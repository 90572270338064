import React from "react";
import {
  MainContainer,
  TitleContainer,
  Heading,
  NavBtn,
  ImageHolder,
  ViewSplit,
  ViewSplitTwo,
  Sub,
  GoBtn,
} from "./MainElements";

const IntroPage = (props) => {
  return (
    <MainContainer>
      <TitleContainer image={props.image}></TitleContainer>
      <ViewSplit>
        <Heading>{props.name}</Heading>
        <hr></hr>
        <Sub>{props.description}</Sub>
        <GoBtn to={props.go}>Learn More</GoBtn>
      </ViewSplit>
    </MainContainer>
  );
};

export default IntroPage;
