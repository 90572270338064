import React from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import edulist from "../../edu.json";
import Markdown from "react-markdown";
import { Spacer, EduCard, NextPrev } from "./EduTemplateElements";
import NestedList from "../../components/ListTree";
import FullbarSolid from "../../components/FullbarSolid";
import { EduContainer } from "./EduTemplateElements";
import { Button } from "@mui/material";
import { Subheader } from "../../components/ListTree/listTreeElements";
import Footer from "../../components/Footer";
import { StrictMode } from "react";
import MathRender from "../../mathRenderer";

function EduPost(props) {
  const { id } = useParams();
  const fetchedPost = {};
  let postExists = false;
  let history = useHistory();
  edulist.forEach((post, i) => {
    if (id === post.id) {
      fetchedPost.id = post.id ? post.id : "Introduction";
      fetchedPost.title = post.title ? post.title : "No title given";
      fetchedPost.author = post.author ? post.author : "No author given";
      fetchedPost.date = post.date ? post.date : "No date given";
      fetchedPost.content = post.content ? post.content : "No content given";
      postExists = true;
    }
  });
  if (postExists === false) {
    return <Redirect to="/404" />;
  }
  let index = edulist.findIndex((fetchedPost) => fetchedPost.id === id);
  let GoPrev = () => {
    if (index >= 1) {
      index = index - 1;
    }
    let prevPost = edulist[index];
    history.push(`/Learn/Structural/${prevPost.id}`);
  };

  let GoNext = () => {
    if (index < edulist.length - 1) {
      index = index + 1;
    }
    let nextPost = edulist[index];
    history.push(`/Learn/Structural/${nextPost.id}`);
  };

  return (
    <>
      <FullbarSolid Location={"WATER RESOURCES"}></FullbarSolid>
      <Subheader>Water Resources</Subheader>
      <EduContainer>
        <NestedList listIn={edulist}></NestedList>

        <EduCard>
          <h1> {fetchedPost.title}</h1>
          <div>
            <NextPrev onClick={GoPrev}>Prev</NextPrev>
            <NextPrev onClick={GoNext}>Next</NextPrev>
          </div>
          <StrictMode>
            <MathRender content={fetchedPost.content}></MathRender>
          </StrictMode>
          <div>
            <NextPrev onClick={GoPrev}>Prev</NextPrev>
            <NextPrev onClick={GoNext}>Next</NextPrev>
          </div>
        </EduCard>
      </EduContainer>
      <Footer></Footer>
    </>
  );
}

export default EduPost;
