import React from "react";
import { FooterContainer } from "./FooterElements";
import logo from "../../pages/Main/mainImage/logo.jpg";
import { Logo, ImageLogo, LinkR, LilContainer } from "./FooterElements";
import styled from "styled-components";
import { SocialIcon } from "react-social-icons";

export const SocialIconMod = styled(SocialIcon)`
  margin-left: 2vw;
  @media screen and (max-width: 1024px) {
    margin: 1vh;
    margin-left: 0vw;
  }
`;

export const LilContainerPhone = styled(LilContainer)`
  display: none;
  @media screen and (max-width: 1024px) {
    margin-top: 0vh;
    font-size: 2.5rem;
    width: 100%;
    display: inline-block;
    height: 5vh;
    float: right;
    text-align: right;
  }
`;

export const LilContainerPhoneIcon = styled(LilContainerPhone)`
  display: none;
  @media screen and (max-width: 1024px) {
    margin-top: -7vh;
    display: grid;
    float: left;
    margin-left: 0;
    margin-right: 0vw;
    padding: 2vh;
    width: 10vw;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <hr></hr>
      <div>
        <Logo>
          <ImageLogo src={logo} alt="SIXSCALED Logo"></ImageLogo>
        </Logo>
        <LilContainer>
          <LinkR to="/">Home</LinkR>
          <LinkR>About Us</LinkR>
          <LinkR>Write for Us</LinkR>
          <LinkR>Contact Us</LinkR>
          <LinkR to="/PrivacyPolicy">Privacy Policy</LinkR>
        </LilContainer>
        <LilContainerPhone>
          <div>
            <LinkR to="/">Home</LinkR>
          </div>
          <div>
            <LinkR>About Us</LinkR>
          </div>
          <div>
            {" "}
            <LinkR>Write for Us</LinkR>
          </div>
          <div>
            <LinkR>Contact Us</LinkR>
          </div>
          <div>
            {" "}
            <LinkR to="/PrivacyPolicy">Privacy Policy</LinkR>
          </div>
        </LilContainerPhone>
        <LilContainer>
          <SocialIconMod
            url="https://www.instagram.com/sixscaled/"
            target={"_blank"}
          />
          <SocialIconMod
            url="https://www.linkedin.com/company/sixscaled/"
            target={"_blank"}
          />
          <SocialIconMod
            url="https://www.youtube.com/channel/UCmB-_qGzbIEw5fQiaf2cLyQ"
            target={"_blank"}
          />
        </LilContainer>
        <LilContainerPhoneIcon>
          {" "}
          <SocialIconMod
            url="https://www.instagram.com/sixscaled/"
            target={"_blank"}
          />
          <SocialIconMod
            url="https://www.linkedin.com/company/sixscaled/"
            target={"_blank"}
          />
          <SocialIconMod
            url="https://www.youtube.com/channel/UCmB-_qGzbIEw5fQiaf2cLyQ"
            target={"_blank"}
          />
        </LilContainerPhoneIcon>
      </div>
      <div></div>
    </FooterContainer>
  );
};

export default Footer;
