import React from "react";
import styled from "styled-components";
import Brick from "../Main/mainImage/back.jpg";

export const BlogListContainer = styled.div`
  width: 47vw;
  text-alignment: left;
  text-align: left;
  background-size: cover;
  align-items: left;
  margin-top: -20vh;
  margin-left: 25vw;
  margin-bottom: 20px;
  text-decoration: none;
  @media (max-width: 1800px) {
    width: 80vw;
    margin-left: 20vw;
  }
  @media (max-width: 1024px) {
    margin-left: 3vw;
    width: 90vw;
  }
  @media (max-width: 600px) {
    margin-left: 0vw;
    width: 90vw;
    align-items: left;
  }
`;

export const BlogListBanner = styled.div`
  height: 30vh;
  background-color: #facdf3;
  text-alignment: left;
  text-align: left;
  background-image: url(${Brick});
  background-size: cover;
  text-alignment: center;
  text-align: center;
  padding-top: 5vh;
  font-size: 3rem;

  color: black;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
