import React from "react";
import styled from "styled-components";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  NavItem,
  NavLinks,
  MobileIcon,
} from "./NavbarElements";

import { FaBars } from "react-icons/fa";
import { propTypes } from "react-markdown";

export const NavbarContainerSolid = styled(NavbarContainer)`
  background-color: #494464;
`;

export const NavLogoGrey = styled(NavLogo)`
  color: white;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-right: 24px;
`;

export const LocationLable = styled.p`
  color: white;
  float: center;
  text-align: center;
  font-size: 0.75rem;
  padding-top: 0px;
  margin-top: 0px;
  margin-left: 24px;
  margin-right: 24px;
`;

export const Divider = styled.hr`
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #f355db;
`;

const NavbarSolid = (props, { toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainerSolid>
          <div>
            <NavLogoGrey to="/">SIXSCALED</NavLogoGrey>
            <Divider></Divider>
            <LocationLable>{props.Location}</LocationLable>
          </div>

          <NavMenu>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavItem>
              <NavLinks to="/">HOME</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/Articles">ARTICLES</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/Learn">LEARN</NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainerSolid>
      </Nav>
    </>
  );
};

export default NavbarSolid;
