import Home from "./pages";
import Blog from "./pages/blog";
import Post from "./pages/posttemplate";
import EduPost from "./pages/EduTemplate";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollRestoration from "react-scroll-restoration";
import NoPage from "./pages/Not Found";
import UtfElements from "./pages/UTF";
import EduList from "./pages/EduList";
import About from "./pages/About";
import Privacy from "./pages/Privacy/privacy";
function App() {
  return (
    <Router>
      <ScrollRestoration />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/Articles" component={Blog} exact />
        <Route path="/Articles/:id" render={(props) => <Post {...props} />} />
        <Route path="/404" component={NoPage} />
        <Route path="/Learn" component={About} exact />
        <Route path="/PrivacyPolicy" component={Privacy} exact />
        <Route
          path="/Learn/Structural/:id"
          render={(props) => <EduPost {...props} />}
        />
        <Route path="/UTF" component={UtfElements} />
      </Switch>
    </Router>
  );
}

export default App;
