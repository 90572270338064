import React from "react";

import {
  TitleContainer,
  Heading,
  AboutBtn,
  MainContainer,
} from "./MainElements";

import About from "../About";
import FullBar from "../../components/FullBar";
import Footer from "../../components/Footer";
import IntroPage from "../introPages";
import IntroPageAlt from "../introPages/alternate";
import { ThemeProvider } from "styled-components";

import susBridge from "../Main/mainImage/struct.png";
import GEO from "../Main/mainImage/geotech.jpg";
import water from "../Main/mainImage/water.jpg";
import transport from "../Main/mainImage/transport.jpg";
import enviro from "../Main/mainImage/enviro.jpg";
import civil from "../Main/mainImage/civil.jpg";
import arch from "../Main/mainImage/arch.jpg";
import NavbarSolid from "../../components/Navbar/NavbarSolid";
import { TotalContainer } from "./MainElements";
import { NavBtn, TestBed } from "./MainElements";
var Scroll = require("react-scroll");
var scroll = Scroll.animateScroll;

const locations = ["geotechnical"];
const themeOne = {
  main: "#d5eced",
  secondary: "black",
};

const themeTwo = {
  main: "blue",
  secondary: "white",
};

let scrollFunc = () => {
  let pageY = window.scrollY;
  let screenHeight = window.innerHeight;
  const scrollHeight =
    screenHeight - (pageY % (screenHeight * 0.8)) + screenHeight * 0.2;
  scroll.scrollMore(scrollHeight, { duration: 1500, delay: 1, smooth: true });
};

let scrollTop = () => {
  scroll.scrollToTop({ duration: 1500, delay: 1, smooth: true });
};

const TitlePage = () => {
  return (
    <div>
      <div id="intro">
        <MainContainer>
          <FullBar></FullBar>
          <TitleContainer>
            <Heading>Civil Engineering Simplified</Heading>
            <AboutBtn to="structural" spy={true} smooth={true} offset={-60}>
              Start Learning Now
            </AboutBtn>
          </TitleContainer>
        </MainContainer>
      </div>
      <NavBtn onClick={scrollTop}>^</NavBtn>
      <NavBtn onClick={scrollFunc}>▼</NavBtn>{" "}
      <div>
        <div id="structural">
          <ThemeProvider theme={themeOne}>
            <IntroPageAlt
              name="Structural Engineering"
              location="geotechnical"
              image={arch}
              description={
                "The design, construction, and maintenance of stuctures such as buildings, bridges and dams."
              }
              go={"Learn/Structural/Introduction"}
            ></IntroPageAlt>
          </ThemeProvider>
        </div>

        <TotalContainer colour={"#c0175a"}>
          <div id="geotechnical">
            <ThemeProvider theme={themeTwo}>
              <IntroPage
                name="Geotechnical Engineering"
                location="water"
                image={GEO}
                description={
                  "Focused on the stability of the soil, geotechnical engineers work to ensure structures are safely integrated with the ground."
                }
                go={"Learn/Geotechnical/Introduction"}
              ></IntroPage>
            </ThemeProvider>
          </div>
        </TotalContainer>
        <div id="water">
          <ThemeProvider theme={themeOne}>
            <IntroPageAlt
              name="Water Engineering"
              location="environmental"
              image={water}
              description={
                "Water Engineers work to preserve, deliver and manage the flow of water through our environment."
              }
              go={"Learn/Water/Introduction"}
            ></IntroPageAlt>
          </ThemeProvider>
        </div>
        <TotalContainer colour={"#7fa455"}>
          <div id="environmental">
            <ThemeProvider theme={themeTwo}>
              <IntroPage
                name="Environmental Engineering"
                location="transportation"
                image={enviro}
                description={
                  "Limits the adverse effects of pollution to humans, animals and the overall environment"
                }
                go={"Learn/Environmental/Introduction"}
              ></IntroPage>
            </ThemeProvider>
          </div>
        </TotalContainer>
        <div id="transportation">
          <ThemeProvider theme={themeOne}>
            <IntroPageAlt
              name="Transportation Engineering"
              location="other"
              image={transport}
              description={
                "Engineers focused on optimizing the flow of people through the built environment"
              }
              go={"Learn/Water/Transportation"}
            ></IntroPageAlt>
          </ThemeProvider>
        </div>

        <div id="footer">
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

export default TitlePage;
