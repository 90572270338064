import React from "react";
import Footer from "../../components/Footer";
import NavbarSolid from "../../components/Navbar/NavbarSolid";
import styled from "styled-components";

export const ContainerPrivate = styled.div`
  width: 80vw;
  align-items: center;
  margin-left: 10vw;
  margin-bottom: 10vh;
`;

const Privacy = () => {
  return (
    <>
      <NavbarSolid></NavbarSolid>
      <ContainerPrivate>
        <h1>SIXSCALED Privacy Policy</h1>
        <h3>Last updated: January 03, 2022</h3>
        <h2>
          You can find our privacy policy&nbsp;
          <a
            href="https://www.privacypolicies.com/live/320698b6-b8be-4d9d-9850-a1633b49174a"
            target={"blank_"}
          >
            here.
          </a>
        </h2>

        <h1>Cookies</h1>
        <h2>
          Third party vendors, including Google use cookies in order to serve
          ads on this site. To learn more about Google's privacy policy and how
          to manage the use of your information, please take a look at&nbsp;
          <a
            href="https://policies.google.com/technologies/partner-sites"
            target={"blank_"}
          >
            Google's Privacy Policy and Terms.
          </a>
        </h2>

        <h1>Contact Us</h1>
        <h2>
          Please feel free to email us at sixscaled@gmail.com with any questions
          or concerns.
        </h2>
      </ContainerPrivate>
      <Footer></Footer>
    </>
  );
};

export default Privacy;
