import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const AboutContainer = styled.div`
  background-color: white;
  height: 80vh;
  width: 90vw;
  text-alignment: center;
  text-align: center;
  background-size: cover;
  align-items: center;
  display: block;
  margin: auto;
  margin-top: 1%;
  margin-bottom: 20vh;
  text-decoration: none;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Spacer = styled.div`
  height: 10vh;
`;

export const NavLinks = styled(LinkR)`
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  text-decoration: none;

  &.active {
    text-decoration: none;
    bottom-border: 2px solid red;
  }
`;
