import React from "react";
import SimpleCard from "../Card/Card";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AboutContainer, NavLinks } from "./AboutElements";
import susBridge from "../Main/mainImage/struct.png";
import GEO from "../Main/mainImage/geotech.jpg";
import water from "../Main/mainImage/water.jpg";
import transport from "../Main/mainImage/transport.jpg";
import enviro from "../Main/mainImage/enviro.jpg";
import civil from "../Main/mainImage/civil.jpg";
import NavbarSolid from "../../components/Navbar/NavbarSolid";
import Footer from "../../components/Footer";

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
});

const About = () => {
  const classes = useStyles();
  return (
    <>
      <NavbarSolid Location={"LEARN"}></NavbarSolid>
      <AboutContainer>
        <Grid
          container
          spacing={5}
          className={classes.gridContainer}
          justifyContent="center"
        >
          <Grid item xs={12} sm={6} md={4}>
            <SimpleCard
              Go={`/Learn/Structural/Introduction`}
              name={"Structural Engineering"}
              image={susBridge}
            ></SimpleCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SimpleCard
              Go={`/Learn/Geotechnical/Introduction`}
              name={"Geotechnical Engineering"}
              image={GEO}
            ></SimpleCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SimpleCard
              Go={`/Learn/Water/Introduction`}
              name={"Water Resources Engineering"}
              image={water}
            ></SimpleCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SimpleCard
              Go={`/Learn/Transportation/Introduction`}
              name={"Transportation Engineering"}
              image={transport}
            ></SimpleCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SimpleCard
              Go={`/Learn/Environmental/Introduction`}
              name={"Environmental Engineering"}
              image={enviro}
            ></SimpleCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SimpleCard name={"See All"} image={civil}></SimpleCard>
          </Grid>
        </Grid>
      </AboutContainer>
      <Footer></Footer>
    </>
  );
};

export default About;
