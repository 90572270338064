import React, { useState } from "react";
import { UtfContainer, UtfIn, UtfInButton } from "./UtfElements";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

export const TextHolder = styled.textarea`
  color: grey;
  width: 20vw;
  height: 9.6vh;
  justify-content: center;
  border: 1px solid black;
  font-size: 1.5rem;
  &:hover {
    border: 2px solid black;
  }
`;

export const Header = styled.div`
  color: white;
  background-color: #494464;
  text-align: center;
  height: 10vh;
  width: 100vw;
  font-size: 4rem;
`;
export const Labels = styled.div`
  width: 100vw;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 15px;
  margin-top: 15px;
  color: black;
`;

export const BoxRow = styled.div`
  width: 100vw;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const ContentContainer = styled.div`
  width: 100vw;
  justify-content: center;
  display: flex;
`;

const UtfElements = () => {
  const [data, setData] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  function getData(val) {
    setSubmit(false);
    const charArr = Object.assign([], val.target.value);
    const newArr = [];

    charArr.forEach((element) => {
      if (element == "è") {
        newArr.push("\\" + "u00E8");
      } else if (element == "é") {
        newArr.push("\\" + "u00E9");
      } else if (element == "ê") {
        newArr.push("\\" + "u00EA");
      } else if (element == "'") {
        newArr.push("\\" + "u0027");
      } else if (element == "à") {
        newArr.push("\\" + "u00E0");
      } else if (element == "ô") {
        newArr.push("\\" + "u00F4");
      } else if (element == "«") {
        newArr.push("\\" + "u00AB");
      } else if (element == "»") {
        newArr.push("\\" + "u00BB");
      } else if (element == "ç") {
        newArr.push("\\" + "u00E7");
      } else if (element == "â") {
        newArr.push("\\" + "u00E2");
      } else {
        newArr.push(element);
      }
    });

    setData(newArr.join(""));
  }

  return (
    <>
      <Header>UTF ENCODER</Header>
      <UtfContainer>
        <Labels>
          <label>Input Text</label>
        </Labels>
        <BoxRow>
          <TextHolder onChange={getData}></TextHolder>
          <UtfInButton onClick={() => setSubmit(true)}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </UtfInButton>
        </BoxRow>
        <Labels>
          <label>Encoded Text</label>
        </Labels>
        <BoxRow>
          <TextHolder value={submit ? data : ""}></TextHolder>
          <CopyToClipboard text={submit ? data : ""} onCopy={onCopyText}>
            <UtfInButton>
              <FontAwesomeIcon icon={faClipboard} />
            </UtfInButton>
          </CopyToClipboard>
        </BoxRow>
      </UtfContainer>
    </>
  );
};

export default UtfElements;
