import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
import susBridge from "../Main/mainImage/struct.png";

export const MainContainer = styled.div`
  height: 70vh;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const TitleContainer = styled.div`
  height: 60vh;
  width: 40vw;
  margin-left: 15vw;
  text-alignment: center;
  text-align: center;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  float: left;

  @media (max-width: 1024px) {
    display: none;
  }
`;
export const ViewSplit = styled.div`
  padding-left: 2vw;
  height: 60vh;
  width: 35vw;
  float: left;
  @media (max-width: 1024px) {
    height: 20vh;
    width: 90vw;
    text-align: center;
    align-items: center;
    padding-left: 4vw;
  }
`;
export const ViewSplitTwo = styled.div`
  padding-left: 6vw;
  height: 60vh;
  width: 35vw;
  float: left;
  @media (max-width: 1024px) {
    height: 20vh;
    width: 90vw;
    text-align: center;
    align-items: center;
    padding-left: 4vw;
  }
`;
export const Heading = styled.h1`
  margin-left: 2vw;
  clear: left;
  text-align: center;

  color: ${(props) => props.theme.secondary};
  font-size: 2rem;
  font-family: "Lucida Console", "Courier New", monospace;

  @media (max-width: 1024px) {
    font-size: 2rem;
    backgroundcolor: ${(props) => props.theme.secondary};
  }
`;

export const Sub = styled.p`
  text-size: 2rem;
  margin-left: 0vw;
  line-height: 1.6;
  clear: right;
  text-align: left;
  color: ${(props) => props.theme.secondary};
  font-size: 1.75rem;
  font-family: "Lucida Console", "Courier New", monospace;
  float: right;

  @media (max-width: 1024px) {
    font-size: 1.75rem;
    text-align: center;
  }
  @media (max-width: 812px) {
    font-size: 1rem;
  }
`;

export const ImageHolder = styled.img`
  clear: left;
  text-align: left;
  width: 30vw;
`;

export const NavBtn = styled(LinkS)`
  background-color: Transparent;
  opacity: 100;
  font-size: 1rem;
  color: ${(props) => props.theme.secondary};
  height: 100px;
  border: 2px solid ${(props) => props.theme.secondary};
  font-family: "Lucida Console", "Courier New", monospace;
  padding: 5px;
  margin: 5px;
  width: auto;
  position: fixed;
  :hover {
    border: 2px solid ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.secondary};
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const GoBtn = styled(LinkR)`
  background-color: Transparent;
  opacity: 50;
  font-size: 1.5rem;
  color: ${(props) => props.theme.secondary};
  border: 2px solid ${(props) => props.theme.secondary};
  font-family: "Lucida Console", "Courier New", monospace;
  padding: 5px;
  margin-left: 15vw;
  text-decoration: none;
  :hover {
    border: 3px solid;
    transition: 0.05s;
  }

  @media (max-width: 1024px) {
    margin-left: 0vw;
  }
  @media (max-width: 812px) {
    font-size: 1.25rem;
  }
`;
