import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkR } from "react-router-dom";

export const TopbarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #494464;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const CloseIcon = styled(FaTimes)`
  color: #fff;
  font-size: 4rem;
  @media screen and (max-width: 768px) {
    grid-template-rows: repeat(5, 100px);
  }

  @media screen and (max-width: 468px) {
    font-size: 2rem;
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  cursor: pointer;
  outline: none;
`;

export const ListItem = styled.p``;

export const TopbarWrapper = styled.div`
  color: #fff;
`;
export const TopbarLink = styled(LinkR)`
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: 4rem;
  line-height: 50px;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #01bf71;
    transition: 0.2s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 468px) {
    font-size: 2rem;
  }
`;

export const TopbarMenu = styled.ul`
  display: none;
  grid-template-columns: 1fr;
  text-align: center;

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-rows: repeat(7, 150px);
  }

  @media screen and (max-width: 768px) {
    grid-template-rows: repeat(5, 150px);
  }

  @media screen and (max-width: 540px) {
    grid-template-rows: repeat(4, 100px);
  }
`;
