import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import React from "react";
import Card from "@material-ui/core/Card";
import Mundi from "../Main/mainImage/mundi.jpg";
import Choolhun from "../Main/mainImage/choolhun.jpg";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { red } from "@mui/material/colors";

export const LinkR = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const BlogCard = styled(Card)`
  width: 20vw;
  margin: 5px;
  @media (max-width: 1800px) {
    width: 30vw;
  }
  @media (max-width: 1024px) {
    width: 40vw;
  }
  @media (max-width: 700px) {
    width: 80vw;
  }
`;

export const BlogMedia = styled(CardMedia)`
  height: 25vh;
`;

export const BlogHeader = styled(CardHeader)`
  height: 10vh;
`;

const Authors = [
  {
    Name: "Ajay Mundi",
    Portrait: Mundi,
    Description: "A man :)",
    Initial: "",
  },
  { Name: "Mackenzie Ryan", Description: "A person", Initial: "" },
  { Name: "Pranesh Choolhun", Description: "A person", Initial: "" },
  { Name: "Thomas Tokarcyzk", Description: "A person", Initial: "" },
  { Name: "Opeoleuwa Onipe", Description: "A person", Initial: "" },
];

function Author(props) {
  const author = props.author;
  if (props.author === "Ajay Mundi") {
    return (
      <CardHeader
        avatar={
          <Avatar src={Mundi} aria-label={props.author}>
            A
          </Avatar>
        }
        title={props.author}
        subheader={props.date}
      ></CardHeader>
    );
  }

  return (
    <CardHeader
      avatar={
        <Avatar src={Choolhun} aria-label={props.author}>
          A
        </Avatar>
      }
      title={props.author}
      subheader={props.date}
    ></CardHeader>
  );
}

export default function RecipeReviewCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getAuthor = () => {
    var Author = Authors.filter(function (o) {
      return o.Name == props.author;
    });
    return Author ? Author[0] : null; // or undefined
  };

  let Auth = getAuthor();
  return (
    <BlogCard>
      <LinkR to={`/Articles/${props.id}`}>
        <CardActionArea>
          <BlogMedia
            component="img"
            height="200"
            image={props.image}
            alt={props.title}
          ></BlogMedia>
          <CardContent sx={{ paddingBottom: 0, paddingTop: 1 }}>
            <Typography variant="h6" color="black">
              {props.title}
            </Typography>
          </CardContent>
          <Author author={props.author}></Author>
        </CardActionArea>
      </LinkR>
    </BlogCard>
  );
}
